.h-wrapper{
    /* color: white; */
    /* background: black; */
}

.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 0.6rem;
    color:#495E57;
    position: fixed;
    background: white;
    z-index: 10;
    border-bottom: 1px solid #495e5767;
}
.h-wrapper>hr{
    height: 10px;
    color: black;
    z-index: 10;
}

.header-logo{
    cursor: pointer;
}

.h-menu{
    gap: 1.5rem;
}

.h-menu li{
    list-style-type: none;
    font-weight: 600;
}

.h-menu li:hover{
    cursor: pointer;
    color: #f4ce14;
    border-bottom: 3px solid var(--primary);
}

.h-menu .active{
    color: #f4ce14;
    border-bottom: 3px solid var(--primary);
}

.menu-icon{
    display: none;
}

@media (max-width:768px){
    .menu-icon{
        display: block;
    }
    .h-menu{
        color: black;
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: whitesmoke;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 4rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: var(--shadow);
        transition: all 300ms ease-in;
    }
}