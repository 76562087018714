.todaySpecial-container{
    overflow: hidden;
    position: relative;
}

.todaySpecial-head{
    margin-bottom: 2rem;
}

.todaySpecial-card{
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 1rem;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease-in;
}

.todaySpecial-card>img{
    width: 100%;
    height: 14rem;
    max-width: 15rem;
    border-radius: 1rem;
}

.todaySpecial-card>:nth-child(2){
    font-size: 1.2rem;
    font-weight: 600;
}

.todaySpecial-card>:nth-child(3){
    font-size: 1.5rem;
}

.todaySpecial-card>:nth-child(4){
    font-size: 0.7rem;
    width: 15rem;
}

.todaySpecial-card:hover{
    scale: 1.05;
    cursor: pointer;
    /* background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(136,160,255,0.46) 217.91%
    ); */
    background: linear-gradient(
        180deg,
        rgba(233, 222, 197, 0.177) 0%,
        rgba(251, 218, 87, 0.735) 217.91%
    );

    box-shadow: var(--shadow);
}

.swiper-horizontal{
    overflow: visible;
}

.todaySpecial-buttons{
    gap: 1rem;
    padding-top: 1rem;
}

.todaySpecial-buttons button{
    font-size: 1.2rem;
    padding: 0.2rem 2rem;
    color: blue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: var(--shadow);
}

