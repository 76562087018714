.menu-container{
    position: relative;
    gap: 2rem;
}

.menus{
    display: grid;
    width: 95%;
    grid-template-columns: 25% auto;
}

.menu{
    list-style: none;
    color: var(--primary2);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 600;
    font-size: 1.3rem;
}

.menu>li:hover{
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;
}

li .active{
    color: orange;
}

.menu-list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    height: 27rem;
    overflow-y: scroll;
    grid-gap: 1.8rem;
    justify-content: space-between;
}

.menu-product{
    width: 12rem;
    height: 12rem;
    /* background-color: rgba(173, 216, 230, 0.314); */
    position: relative;
    overflow: hidden;
    /* padding: 1rem; */
    display: flex;
    border-radius: 1rem;
    /* gap: 2rem; */
}
.menu-product:hover{
    scale: 1.03;
    cursor: pointer;
    box-shadow: var(--shadow);
}
.menu-box{
    height: 12rem;
    width:12rem;
    padding: 0.4rem;
    /* background: blue; */
}

.menu-name {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 2px;
}
.menu-detail{
    padding: 1px;
    font-size: 0.8rem;
}
.name{
    color: black;
    background-color: rgba(255, 255, 255, 0.522);
    padding: 3px;
    border-radius: 5px;
    /* filter: blur(1px); */
    /* border-radius: 10%; */
}
.menu-price{
    /* display: flex; */
    /* justify-content: space-between; */
    background:rgba(255, 255, 255, 0.522);
    padding: 3px;
    border-radius: 5px;
    font-weight: bold;
}

.menu-product>img{
    /* top: 3rem; */
    width: 12rem;
    height: 12rem;
    position: absolute;
    border-radius: 1rem;
    z-index: -1;
}