.FooterWrapper {
  width: 100%;
  /* margin-top: 5rem; */
}

.FooterWrapper > hr {
  width: 100%;
  height: 1px;
  border: none;
  background: black;
  margin-top: 1rem;
}

.cFooter {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.Logo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.Logo > img {
  width: 7rem;
}

.footerBlock {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 12rem;
}
.detail {
  display: flex;
  flex-direction: column;
  width: inherit;
  font-size: 0.7rem;
  gap: 1rem;
}
.detail > :nth-of-type(1) {
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}

.pngLine:hover {
  cursor: pointer;
  text-decoration: underline;
}

.pngLine {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.pngLine>span{
    font-size: 13px;
}
.pngLine>a{
    font-size: 13px;
    font-weight: normal;
}

.pngIcon {
  width: 1.2rem;
  height: 1.2rem;
  color: #495e57;
}

.facebook {
  color: #316ff6;
}

.instagram {
    color: white;
    border-radius: 0.3rem;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.twitter {
  color: #000e17;
}

.youtube {
  color: #cd201f;
}

.copyright>hr{
    height: 0.5px;
    width: 80%;
}

.copyright>span{
    font-size: 0.7rem;
}

@media (max-width: 768px) {
  .cFooter{
      flex-direction: column;
  }
  .cFooter{
    align-items: center;
    text-align: center;
    width: 100%;
}
}
